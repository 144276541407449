// ++++++++++++++++++++++++++++++

// CLASES GENERALES

// ++++++++++++++++++++++++++++++

.between {
	justify-content: space-between;
}

// --------------------------------
