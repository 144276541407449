/* Variables */
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;600&display=swap');
$white: #fff;
@keyframes jump {
	0% {
		transform: scale3d(1, 1, 1);
	}
	30% {
		transform: scale3d(1.25, 0.75, 1);
	}
	40% {
		transform: scale3d(0.75, 1.25, 1);
	}
	50% {
		transform: scale3d(1.15, 0.85, 1);
	}
	65% {
		transform: scale3d(0.95, 1.05, 1);
	}
	75% {
		transform: scale3d(1.05, 0.95, 1);
	}
	100% {
		transform: scale3d(1, 1, 1);
	}
}
body {
	top: 0 !important;
}
* {
	margin: 0;
	padding: 0;
	font-size: 17px;
	box-sizing: border-box;
	outline: none;
	font-family: 'Manrope', sans-serif;
}
h1 {
	font {
		font-size: 2.5rem;
	}
	font-size: 2.5rem;
}
h2 {
	font {
		font-size: 2rem;
	}
	font-size: 2rem;
}
h3 {
	font {
		font-size: 1.5rem;
	}
	font-size: 1.5rem;
}
label {
	font {
		font-size: 1.1rem;
	}
	font-size: 1.1rem;
}
.App {
	display: flex;
	width: 100%;
	min-height: 100vh;
	overflow: auto;
	justify-content: center;
	align-items: center;
	position: relative;
	padding: 55px 20px;
	background-color: #eaeaea;
}
.background {
	display: block;
	width: 100%;
	height: 100%;
	background-color: $white;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}
}
.container-form {
	width: 490px;
	position: relative;
	border-radius: 5px;
	border: none;
	overflow: hidden;
	box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.3);
}
.container-survey {
	display: flex;
	flex-direction: column;
	width: 100%;
}
.section {
	background-color: $white;
	opacity: 0;
	height: 0;
	overflow: hidden;
	transition: all 0.4s;
}
.section.showing {
	opacity: 1;
	height: auto;
}
.not-found {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	z-index: 100;
}

.title-section {
	display: block;
	text-align: center;
	padding: 10px 0;
}
.ask-container {
	display: block;
	width: 100%;
	padding: 10px;
	label {
		text-align: center;
		display: block;
		font-weight: 600;
	}
}
.select-container {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-wrap: wrap;
	label.with-image {
		span {
			font {
				font-size: 0.8rem;
			}
			font-size: 0.8rem;
		}
	}
	label.with-image {
		img {
			display: block;
			width: 85%;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 5px;
		}
	}
	label {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		padding: 10px;
		margin: 10px;
		position: relative;
		text-align: center;
		font-weight: 300;
		input {
			display: none;
		}
	}
	label.selected {
		animation: jump 0.9s both;
	}
	label.no-selected {
		opacity: 0.5;
	}
}
.next-and-back-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px;
	a {
		text-decoration: none;
	}
}
// Estilos del alert
@keyframes hiden {
	0% {
		top: 50px;
	}
	100% {
		top: -100px;
	}
}
@keyframes show {
	0% {
		top: -100px;
	}
	100% {
		top: 50px;
	}
}
.alert {
	display: flex;
	align-items: center;
	position: fixed;
	top: -100px;
	padding: 20px 30px;
	border-radius: 5px;
	z-index: 2;
	box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.5);
	animation: show 0.25s linear;
	max-width: 90vw;
	&.warning {
		top: 50px;
		background-color: orangered;
	}
	&.success {
		top: 50px;
		background-color: rgb(0, 167, 75);
	}
	&.hiden {
		animation: hiden 0.25s linear;
		top: -100px;
	}
	p {
		color: #fff;
	}
	a {
		display: block;
		width: 20px;
		height: 20px;
		margin-left: 10px;
		background-image: url('../img/resources/x.png');
		background-position: center;
		background-repeat: no-repeat;
		background-size: 60%;
	}
}
.link-opinate-google {
	display: inline-block;
	text-align: center;
	margin: 15px auto;
	font-size: 1rem;
	font-weight: 600;
	text-decoration: none;
	padding: 10px 15px;
	font {
		font-size: 1rem;
		font-weight: 600;
	}
}
// Estilos del loader
.loader {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.5);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 100;
	@keyframes rotation {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
	&::before {
		content: '';
		width: 48px;
		height: 48px;
		border-radius: 50%;
		display: inline-block;
		border-top: 3px solid #fff;
		border-right: 3px solid transparent;
		box-sizing: border-box;
		animation: rotation 0.6s linear infinite;
	}
}

// Estilos del traductor
div#goog-gt-tt {
	display: none !important;
}
.language {
	position: absolute;
	top: 7px;
	left: 20px;
	z-index: 10;
}
body > div.skiptranslate {
	opacity: 0;
	overflow: hidden;
	height: 0;
}
.goog-te-gadget img {
	display: none;
}
.goog-te-gadget-simple::before {
	content: '';
	display: inline-block;
	vertical-align: middle;
	width: 25px;
	height: 25px;
	background-color: #eaeaea;
	border-radius: 50%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100%;
	background-image: url('../img/resources/translate.png');
}
.goog-te-gadget-simple {
	display: inline-block;
	cursor: pointer;
	border: none;
	background-color: #fff !important;
	padding: 5px 10px !important;
	border-radius: 18px !important;
}
.goog-te-gadget-simple > span > a > span:first-child {
	margin-right: 12px;
	font-size: 0.85srem !important;
}
.goog-te-gadget-simple > span > a > span {
	border: none !important;
}
font {
	background-color: transparent !important;
	box-shadow: none !important;
}
.VIpgJd-ZVi9od-xl07Ob-OEVmcd {
	box-shadow: none !important;
	background: white;
	z-index: 2 !important;
	overflow: hidden;
	border-radius: 5px;
	margin-top: 2px;
	margin-left: 35px;
}
.VIpgJd-ZVi9od-aZ2wEe-wOHMyf {
	display: none;
}

// Estilos del admin
.login-section {
	display: flex;
	flex-direction: column;
	background-color: $white;
	padding: 50px 20px;
	width: 320px;
	text-align: center;
	border-radius: 5px;
	box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
	img {
		display: block;
		width: 50px;
		margin: 0 auto;
	}
	form {
		display: block;
		div {
			display: flex;
			flex-direction: column;
			text-align: left;
			margin: 10px 0;
			input {
				display: block;
				width: 100%;
				padding: 10px;
				border-radius: 5px;
				border: none;
				background-color: #eaeaea;
			}
		}
		button {
			display: block;
			background-color: rgb(0, 66, 44);
			color: #fff;
			padding: 7px 0;
			border-radius: 2px;
			width: 100%;
			margin-top: 20px;
			border: none;
		}
	}
}

.admin-panel {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 88vh;
	padding-top: 40px;
	.menu-panel {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: #fff;
		box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
		padding: 10px 20px;
		border-radius: 4px;
		position: fixed;
		top: 10px;
		width: 98%;
		div {
			display: flex;
			align-items: center;
			p {
				font-weight: 600;
			}
		}
		a {
			text-decoration: none;
			font-weight: 600;
		}
	}
	.widgets {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		div,
		a {
			min-width: 220px;
			background-color: #fff;
			box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
			padding: 20px;
			border-radius: 4px;
			flex: 1;
			margin-right: 35px;
			margin-bottom: 20px;
			height: 100px;
			&:last-child {
				margin-right: 0;
			}
			span {
				font-size: 1.2rem;
				font-weight: 600;
			}
		}
		.excel {
			display: flex;
			text-decoration: none;
			align-items: center;
			font-weight: 600;
			font-size: 1.2rem;
			color: rgb(0, 66, 44);
			img {
				width: 35px;
				height: 35px;
				margin-right: 15px;
			}
		}
	}
	.table-container {
		background-color: #fff;
		box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
		padding: 20px;
		border-radius: 4px;
		span {
			font-size: 1.2rem;
			font-weight: 600;
			display: block;
			margin-bottom: 15px;
		}
		.table {
			width: 100%;
			overflow-x: auto;
			padding-bottom: 10px;
			table {
				font-family: Arial, Helvetica, sans-serif;
				border-collapse: collapse;
				width: 100%;
				td,
				th {
					border: 1px solid #ddd;
					padding: 8px;
					white-space: nowrap;
					text-align: center;
				}
				tr:nth-child(even) {
					background-color: #f2f2f2;
				}
				tr:hover {
					background-color: #ddd;
				}
				th {
					padding-top: 12px;
					padding-bottom: 12px;
					text-align: left;
					background-color: rgb(0, 66, 44);
					color: white;
				}
			}
		}
	}
}
